import React, { useEffect, useContext, useRef, useState } from 'react';
import P from 'prop-types';
import { ModalContext } from '../../contexts/ModalContext';
import ArrowButtom from '../ArrowButtom';
import './style.scss';
import CloseButtom from '../CloseButtom';

function FullScreenModal() {
  const { classShowHide, galleryImages, imageIndex, setImageIndex, imageId, imageSrc, imageAlt, hideModal } =
    useContext(ModalContext);
  const [imageInfos, setImageInfos] = useState({});
  const modalWrapper = useRef();
  const closeButtom = useRef();

  const closeModal = (elementClicked) => {
    if (elementClicked.target === modalWrapper.current || elementClicked.target === closeButtom.current) {
      hideModal();
      setImageIndex(null);
      setImageInfos({});
    }
  };

  useEffect(() => {
    if (imageIndex != null) {
      setImageInfos({
        index: imageIndex,
        id: imageId,
        alt: imageAlt,
        description: '',
        backgroundImage: imageSrc,
      });
    }
  }, [imageIndex]);

  return (
    <div
      className={`modal-wrapper ${classShowHide}`}
      ref={modalWrapper}
      onClick={(elementClicked) => {
        closeModal(elementClicked);
      }}
    >
      <div className="close_buttom-wrapper">
        <CloseButtom refProp={closeButtom} />
      </div>
      <ArrowButtom
        position="modal-left left"
        onClick={() => {
          let prevIndex = imageInfos.index - 1 < 0 ? galleryImages.length - 1 : imageInfos.index - 1;
          setImageInfos({
            index: prevIndex,
            id: galleryImages[prevIndex].id,
            alt: galleryImages[prevIndex].alt,
            description: '',
            backgroundImage: galleryImages[prevIndex].backgroundImage,
          });
        }}
      />
      <ArrowButtom
        position="modal-right right"
        onClick={() => {
          let nextIndex = imageInfos.index + 1 >= galleryImages.length - 1 ? 0 : imageInfos.index + 1;
          setImageInfos({
            index: nextIndex,
            id: galleryImages[nextIndex].id,
            alt: galleryImages[nextIndex].alt,
            description: '',
            backgroundImage: galleryImages[nextIndex].backgroundImage,
          });
        }}
      />

      <div className="modal">
        <figure className="image">
          <img
            src={imageInfos.backgroundImage === undefined ? '' : imageInfos.backgroundImage}
            alt={imageInfos.alt === undefined ? '' : imageInfos.alt}
          />
        </figure>
        <figcaption>{`${imageInfos.alt === undefined ? '' : imageInfos.alt}`}</figcaption>
      </div>
    </div>
  );
}

FullScreenModal.propTypes = {
  imageAlt: P.string,
  imageSrc: P.string,
  classShowHide: P.string,
  setcClassShowHide: P.func,
  interval: P.object,
};
export default FullScreenModal;
