import React from 'react';

import './style.scss';

function Contact_Infos() {
  return (
    <article className="contacts_infos">
      <div id="street">
        <address>
          Rua Conceição, 233, Sala 912 <br />
          Centro, Campinas, São Paulo - Brasil <br />
          Tel: (19) 3242-0232
        </address>
      </div>
      <a href="mailto:comercial@baumec.com.br">comercial@baumec.com.br</a>
      <div id="opening_hours">
        <p>HORÁRIO DE FUNCIONAMENTO:</p>
        <p>Seg - Sex: 8:00 - 17:30</p>
      </div>
    </article>
  );
}

export default Contact_Infos;
