import React from 'react';
import './style.scss';

function Footer() {
  return (
    <footer className="footer-wrapper">
      <span>DESDE 1985 :: BAUMEC CONSTRUÇÕES PRÉ-MOLDADAS LTDA.</span>
    </footer>
  );
}

export default Footer;
