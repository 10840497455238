import React from 'react';
import P from 'prop-types';
import './style.scss';

function Product_Card(props) {
  const { title, description, image } = props;
  return (
    <article className="product_card-wrapper">
      <div
        className="product_card-image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="product_card-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </article>
  );
}

Product_Card.propTypes = {
  title: P.string,
  description: P.string,
  image: P.string,
};

export default Product_Card;
