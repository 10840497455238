import React from 'react';
import P from 'prop-types';

import './style.scss';

function CloseButtom({ refProp }) {
  return (
    <div
      className="close_buttom"
      ref={refProp}
    />
  );
}

CloseButtom.propTypes = {
  refProp: P.oneOfType([P.func, P.shape({ current: P.any })]),
};

export default CloseButtom;
