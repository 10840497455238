import React, { createContext, useState } from 'react';
import P from 'prop-types';

export const ModalContext = createContext();

function ModalProvider({ children }) {
  const [classShowHide, setClassShowHide] = useState('modalHide');
  const [isModalVisible, setModalVisible] = useState(false);
  const [galleryImages, setGalleryImages] = useState();
  const [imageId, setImageId] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [imageAlt, setImageAlt] = useState();
  const [imageIndex, setImageIndex] = useState();

  const showModal = () => {
    document.body.style.overflow = 'hidden';
    setClassShowHide('modalShow');
    setModalVisible(true);
  };

  const hideModal = () => {
    document.body.style.overflow = 'auto';
    setClassShowHide('modalHide');
    setModalVisible(false);
  };

  const contextValue = {
    classShowHide,
    galleryImages,
    setGalleryImages,
    isModalVisible,
    showModal,
    hideModal,
    imageSrc,
    setImageSrc,
    imageAlt,
    setImageAlt,
    imageIndex,
    setImageIndex,
    imageId,
    setImageId,
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
}

ModalProvider.propTypes = {
  children: P.node.isRequired,
};

export default ModalProvider;
