import React, { createContext, useState } from 'react';
import P from 'prop-types';
import { GalleryConfig } from '../../config';

export const ProcessBarContext = createContext();

function ProcessBarProvider({ children }) {
  const [progressBarWidth, setProgressBarWidth] = useState();
  const [progressBarTransition, setProgressBarTransition] = useState();
  const { masterIntervalDelay } = GalleryConfig;

  //

  const resetProgressBar = () => {
    const timeOutGap = 50;
    setProgressBarTransition('width 0s');
    setProgressBarWidth('0%');
    setTimeout(() => {
      setProgressBarTransition(`width ${masterIntervalDelay - timeOutGap}ms linear`);
      setProgressBarWidth('100%');
    }, timeOutGap);
  };

  const stopProgressBar = () => {
    setProgressBarTransition('width 1s ease');
    setProgressBarWidth('0%');
  };
  //

  const contextValue = { progressBarWidth, progressBarTransition, resetProgressBar, stopProgressBar };

  //
  return <ProcessBarContext.Provider value={contextValue}>{children}</ProcessBarContext.Provider>;
}
ProcessBarProvider.propTypes = {
  children: P.node.isRequired,
};

export default ProcessBarProvider;
