import React, { useEffect, useRef } from 'react';
import P from 'prop-types';
import './style.scss';
import { isInViewport } from '../../../services/isInViewport';
import { isSafariOnDeskton, isOnMobile } from '../../../services/deviceReveal';
import Product_Card from '../../../components/Product_Card';

function Products({ id }) {
  const divRef = useRef(null);

  useEffect(() => {
    const offsetTop = divRef.current.offsetTop;

    divRef.current.style.backgroundPosition = 'right ' + offsetTop + 'px';

    const moveBackground = (motionCoefficient) => {
      if (isInViewport(divRef.current)) {
        const currentPosition = window.pageYOffset;
        divRef.current.style.backgroundPosition = 'right ' + (offsetTop - currentPosition * motionCoefficient) + 'px';
      }
    };

    const eventHanler = () => {
      isOnMobile() ? moveBackground(0.04) : moveBackground(0.3);
    };

    if (isSafariOnDeskton()) {
      divRef.current.style.backgroundAttachment = 'scroll';
      divRef.current.style.backgroundSize = 'auto';
      // O "movimento do background" foi retirado de quando o usuario está no Safari-Desktop porque a rolagem fica "truncada" nesse browser. (testado em um MacBook Pro 13 2011).
    } else {
      window.addEventListener('scroll', eventHanler);
    }

    return () => {
      window.removeEventListener('scroll', eventHanler);
    };
  }, []);

  return (
    <section
      id={id}
      ref={divRef}
      className="products-wrapper"
    >
      <div className="products-card-wrapper container">
        <Product_Card
          title={'ESTRUTURAS'}
          description={
            'Fabricação de peças pré-moldadas, incluindo pilares, vigas, lajes e painéis em concreto armado e protendido. Equipe altamente qualificada que garante precisão e qualidade para atender às suas necessidades.'
          }
          image={require('../../../media/images/assets/product_cards/estruturas.jpg')}
        />
        <Product_Card
          title={'COBERTURAS'}
          description={
            'Estrutura de Coberturas com grandes vãos livres, desde 10m até 35m ou mais, em tesouras e terças pré-moldadas. Nosso sistema oferece uma opção econômica e proporciona um aspecto limpo e agradável para a sua obra.'
          }
          image={require('../../../media/images/assets/product_cards/coberturas.jpg')}
        />
        <Product_Card
          title={'GALPÕES'}
          description={
            'Pacote completo para galpões pré-moldados, realizando todos os trabalhos necessários, desde a fundação até mezaninos, coberturas e fechamentos laterais. Garantimos que o seu projeto será executado com precisão e eficiência.'
          }
          image={require('../../../media/images/assets/product_cards/galpoes.jpg')}
        />
        <Product_Card
          title={'PRÉDIOS'}
          description={
            'Executamos estruturas pré-moldadas para edifícios sem limites de altura e pavimentos, o que permite uma redução significativa no prazo de construção do seu empreendimento. Contamos com equipe própria para projeto e calculo'
          }
          image={require('../../../media/images/assets/product_cards/predios.jpg')}
        />
      </div>
    </section>
  );
}

Products.propTypes = {
  id: P.string,
};

export default Products;
