import React from 'react';
import P from 'prop-types';

import './style.scss';

function ArrowButtom({ position, onClick }) {
  return (
    <div
      className={`arrow ${position}`}
      onClick={onClick}
    ></div>
  );
}

ArrowButtom.propTypes = {
  position: P.string,
  onClick: P.func,
  startInterval: P.func,
  changeImagesDirection: P.func,
};

export default ArrowButtom;
