import React from 'react';
import ProcessBarProvider from './contexts/ProcessBarContext';
import GalleryContainer from './components/GalleryContainer';
import ModalProvider from './contexts/ModalContext';

function AppGallery() {
  return (
    <ProcessBarProvider>
      <ModalProvider>
        <GalleryContainer />
      </ModalProvider>
    </ProcessBarProvider>
  );
}

export default AppGallery;
