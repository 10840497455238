import React, { useEffect, useRef } from 'react';
import './style.scss';
import { isInViewport } from '../../../services/isInViewport';
import { isSafariOnDeskton, isOnMobile } from '../../../services/deviceReveal';

function Gap() {
  const divRef = useRef(null);

  useEffect(() => {
    const offsetTop = divRef.current.offsetTop + 100;
    divRef.current.style.backgroundPosition = 'right ' + offsetTop + 'px';

    const moveBackground = (motionCoefficient) => {
      if (isInViewport(divRef.current)) {
        const currentPosition = window.pageYOffset;
        divRef.current.style.backgroundPosition = 'right ' + (offsetTop - currentPosition * motionCoefficient) + 'px';
      }
    };

    const eventHanler = () => {
      isOnMobile() ? moveBackground(0.04) : moveBackground(0.15);
    };

    if (isSafariOnDeskton()) {
      divRef.current.style.backgroundAttachment = 'scroll';
      divRef.current.style.backgroundSize = 'cover';
      divRef.current.style.backgroundPosition = 'center';
      // O "movimento do background" foi retirado de quando o usuario está no Safari-Desktop porque a rolagem fica "truncada" nesse browser. (testado em um MacBook Pro 13 2011).
    } else {
      window.addEventListener('scroll', eventHanler);
    }

    return () => {
      window.removeEventListener('scroll', eventHanler);
    };
  }, []);

  return (
    <section
      ref={divRef}
      className="gap-wrapper"
    >
      <div className="white-filter"></div>
    </section>
  );
}

export default Gap;
