import React from 'react';
import P from 'prop-types';
import AppGallery from './AppGallery';

import './style.scss';

function Gallery({ id }) {
  return (
    <section
      id={id}
      className="gallery-wrapper"
    >
      <p>VEJA ALGUMAS DE NOSSOS OBRAS REALIZADAS</p>
      <AppGallery />
    </section>
  );
}
Gallery.propTypes = {
  id: P.string,
};

export default Gallery;
