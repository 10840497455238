import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Link } from 'react-scroll';
import Hamburger from '../Hamburger';

function Menu_Items() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isMenuTransition, setIsMenuTransition] = useState(false);
  const menu = useRef();

  useEffect(() => {
    setTimeout(() => {
      setIsMenuTransition(false);
    }, 400);
  });

  const handleClick = () => {
    isMenuOpened ? setIsMenuOpened(false) : setIsMenuOpened(true);
    document.body.style.overflow = 'auto';
    isMenuTransition ? setIsMenuTransition(false) : setIsMenuTransition(true);

    setTimeout(() => {
      isMenuTransition ? setIsMenuTransition(false) : setIsMenuTransition(true);
    }, 400);
  };

  return (
    <nav className="menu-wrapper">
      <Hamburger
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        isMenuTransition={isMenuTransition}
        setIsMenuTransition={setIsMenuTransition}
      />
      <ul
        className={`menu ${isMenuOpened ? 'menu-visible' : ''} ${isMenuTransition ? 'menu-transition' : ''}`}
        ref={menu}
      >
        <li className="menu-item">
          <Link
            spy={true}
            smooth={true}
            offset={0}
            duration={20}
            to="home"
            onClick={() => {
              handleClick();
            }}
          >
            HOME
          </Link>
        </li>
        <li className="menu-item">
          <Link
            spy={true}
            smooth={true}
            offset={-75}
            duration={20}
            to="services"
            onClick={() => {
              handleClick();
            }}
          >
            SERVIÇOS
          </Link>
        </li>
        <li className="menu-item">
          <Link
            spy={true}
            smooth={true}
            offset={-110}
            duration={20}
            to="gallery"
            onClick={() => {
              handleClick();
            }}
          >
            OBRAS/FOTOS
          </Link>
        </li>
        <li className="menu-item">
          <Link
            spy={true}
            smooth={true}
            offset={-92}
            duration={20}
            to="contact"
            onClick={() => {
              handleClick();
            }}
          >
            CONTATO
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Menu_Items;
