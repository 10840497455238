import React, { useContext } from 'react';
import P from 'prop-types';
import { ModalContext } from '../../contexts/ModalContext';

import './style.scss';

function GalleryCard({ allImages, imageId, refProp, imageIndex, imageSrc, imageAlt, figcaption }) {
  const { setGalleryImages, setImageId, setImageSrc, setImageAlt, setImageIndex, isModalVisible, showModal } =
    useContext(ModalContext);
  const handleClick = () => {
    setGalleryImages(allImages);
    setImageId(imageId);
    setImageSrc(imageSrc);
    setImageAlt(imageAlt);
    setImageIndex(imageIndex);

    if (isModalVisible === false) {
      showModal();
    }
  };

  return (
    <>
      <figure
        id={imageId}
        ref={refProp}
        className="gallery-card"
        style={{ backgroundImage: `url(${imageSrc})` }}
        onClick={() => {
          handleClick();
        }}
      >
        <div className="hover_box">
          <figcaption>{figcaption}</figcaption>
        </div>
      </figure>
    </>
  );
}

GalleryCard.propTypes = {
  allImages: P.array,
  imageIndex: P.number,
  imageId: P.number,
  imageSrc: P.string,
  imageAlt: P.string,
  refProp: P.oneOfType([P.func, P.shape({ current: P.any })]),
  figcaption: P.string,
  interval: P.object,
};

export default GalleryCard;
