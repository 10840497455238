import React from 'react';
import './style.scss';

function Slogan() {
  return (
    <section className="slogan-wrapper container">
      <h1>
        <strong>BAUMEC: UMA EMPRESA DE CONFIANÇA PARA O SEU PROJETO</strong>
      </h1>
      <p>
        A Baumec é uma empresa comprometida em oferecer um atendimento personalizado e próximo ao cliente, garantindo a
        transparência e a qualidade técnica do serviço prestado. Temos como principal prioridade, o prazo da obra, e
        trabalhamos constantemente para entregar o projeto dentro do tempo estabelecido, sem comprometer a qualidade do
        trabalho. Além disso, a Baumec é reconhecida por utilizar alta tecnologia na elaboração dos seus projetos e
        produtos inovadores, o que garante aos clientes soluções modernas e eficientes.
      </p>
      <h2>
        {'"'}A SATISFAÇÃO DO CLIENTE A NOSSA PRINCIPAL REFERÊNCIA{'"'}
      </h2>
    </section>
  );
}

export default Slogan;
