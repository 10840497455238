import { GalleryConfig } from './config';
const { sortImagesOnLoad, orderBy } = GalleryConfig;

const data = [
  {
    id: 1,
    alt: 'HABIBS - São Paulo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00001.jpg'),
  },
  {
    id: 2,
    alt: 'DHOLLANDIA - Sumaré/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00002.jpg'),
  },
  {
    id: 3,
    alt: 'DHOLLANDIA - Sumaré/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00003.jpg'),
  },
  {
    id: 4,
    alt: 'DHOLLANDIA - Sumaré/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00004.jpg'),
  },
  {
    id: 5,
    alt: 'DHOLLANDIA - Sumaré/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00005.jpg'),
  },
  {
    id: 6,
    alt: 'DHOLLANDIA - Sumaré/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00006.jpg'),
  },
  {
    id: 7,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00007.jpg'),
  },
  {
    id: 8,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00008.jpg'),
  },
  {
    id: 9,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00009.jpg'),
  },
  {
    id: 10,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00010.jpg'),
  },
  {
    id: 11,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00011.jpg'),
  },
  {
    id: 12,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00012.jpg'),
  },
  {
    id: 13,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00013.jpg'),
  },
  {
    id: 14,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00014.jpg'),
  },
  {
    id: 15,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00015.jpg'),
  },
  {
    id: 16,
    alt: 'BAUER DO BRASIL - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00016.jpg'),
  },
  {
    id: 17,
    alt: 'BAUER DO BRASIL - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00017.jpg'),
  },
  {
    id: 18,
    alt: 'DHOLLANDIA - Sumaré/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00018.jpg'),
  },
  {
    id: 19,
    alt: 'BIOMIN - Piracicaba/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00019.jpg'),
  },
  {
    id: 20,
    alt: 'BIOMIN - Piracicaba/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00020.jpg'),
  },
  {
    id: 21,
    alt: 'BIOMIN - Piracicaba/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00021.jpg'),
  },
  {
    id: 22,
    alt: 'CLAP - São Paulo / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00022.jpg'),
  },
  {
    id: 23,
    alt: 'CLAP - São Paulo / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00023.jpg'),
  },
  {
    id: 24,
    alt: 'PROVIDER - Louveira/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00024.jpg'),
  },
  {
    id: 25,
    alt: 'PROVIDER - Louveira/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00025.jpg'),
  },
  {
    id: 26,
    alt: 'SAINT GOBAIN | NEFELINA - Lavrinhas/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00026.jpg'),
  },
  {
    id: 27,
    alt: 'SAINT GOBAIN | NEFELINA - Lavrinhas/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00027.jpg'),
  },
  {
    id: 28,
    alt: 'STEMMI - São José dos Campos / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00028.jpg'),
  },
  {
    id: 29,
    alt: 'STEMMI - São José dos Campos / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00029.jpg'),
  },
  {
    id: 30,
    alt: 'ATACADÃO - Araraquara/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00030.jpg'),
  },
  {
    id: 31,
    alt: 'CLAP - São Paulo / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00031.jpg'),
  },
  {
    id: 32,
    alt: 'ESCADA PRÉ-MOLDADA',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00032.jpg'),
  },
  {
    id: 33,
    alt: 'ESCADA PRÉ-MOLDADA',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00033.jpg'),
  },
  {
    id: 34,
    alt: 'ATACADÃO - Araraquara/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00034.jpg'),
  },
  {
    id: 35,
    alt: 'ATACADÃO - Araraquara/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00035.jpg'),
  },
  {
    id: 36,
    alt: 'ATACADÃO - Araraquara/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00036.jpg'),
  },
  {
    id: 37,
    alt: 'MUNIR - Campinas/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00037.jpg'),
  },
  {
    id: 38,
    alt: 'MUNIR - Campinas/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00038.jpg'),
  },
  {
    id: 39,
    alt: 'ATACADÃO - Piracicaba/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00039.jpg'),
  },
  {
    id: 40,
    alt: 'AD-TECH - Monte Mor / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00040.jpg'),
  },
  {
    id: 41,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00041.jpg'),
  },
  {
    id: 42,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00042.jpg'),
  },
  {
    id: 43,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00043.jpg'),
  },
  {
    id: 44,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00044.jpg'),
  },
  {
    id: 45,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00045.jpg'),
  },
  {
    id: 46,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00046.jpg'),
  },
  {
    id: 47,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00047.jpg'),
  },
  {
    id: 48,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00048.jpg'),
  },
  {
    id: 49,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00049.jpg'),
  },
  {
    id: 50,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00050.jpg'),
  },
  {
    id: 51,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00051.jpg'),
  },
  {
    id: 52,
    alt: 'ARAYMOND - Vinhedo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00052.jpg'),
  },
  {
    id: 53,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00053.jpg'),
  },
  {
    id: 54,
    alt: 'VALFILM | TECNOVAL - Itamonte/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00054.jpg'),
  },
  {
    id: 55,
    alt: 'FDE - São Paulo / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00055.jpg'),
  },
  {
    id: 56,
    alt: 'STORANI - Vinhedo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00056.jpg'),
  },
  {
    id: 57,
    alt: 'STORANI - Vinhedo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00057.jpg'),
  },
  {
    id: 58,
    alt: 'STORANI - Vinhedo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00058.jpg'),
  },
  {
    id: 59,
    alt: 'STORANI - Vinhedo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00059.jpg'),
  },
  {
    id: 60,
    alt: 'BAUER - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00060.jpg'),
  },
  {
    id: 61,
    alt: 'BAUER - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00061.jpg'),
  },
  {
    id: 62,
    alt: 'BAUER - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00062.jpg'),
  },
  {
    id: 63,
    alt: 'BAUER - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00063.jpg'),
  },
  {
    id: 64,
    alt: 'STORANI - Vinhedo/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00064.jpg'),
  },
  {
    id: 65,
    alt: 'SUPERMERCADO POPULAR - Itapira/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00065.jpg'),
  },
  {
    id: 66,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00066.jpg'),
  },
  {
    id: 67,
    alt: 'BAUER - São João da Boa Vista / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00067.jpg'),
  },
  {
    id: 68,
    alt: 'AURICCHIO - Mogi das Cruzes / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00068.jpg'),
  },
  {
    id: 69,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00069.jpg'),
  },
  {
    id: 70,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00070.jpg'),
  },
  {
    id: 71,
    alt: 'AD-TECH - Monte Mor / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00071.jpg'),
  },
  {
    id: 72,
    alt: 'AD-TECH - Monte Mor / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00072.jpg'),
  },
  {
    id: 73,
    alt: 'AD-TECH - Monte Mor / SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00073.jpg'),
  },
  {
    id: 74,
    alt: 'BAUDUCCO - Extrema/MG',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00074.jpg'),
  },
  {
    id: 75,
    alt: 'ATACADÃO - Araraquara/SP',
    description: '',
    backgroundImage: require('../../../media/images/pictures/obras/pic00075.jpg'),
  },
];

if (orderBy != []) {
  data.sort(function (a, b) {
    for (let i = 0; i < orderBy.length; i++) {
      if (a[orderBy[i]] !== b[orderBy[i]]) {
        return a[orderBy[i]] < b[orderBy[i]] ? -1 : 1;
      }
    }
    return 0;
  });
}

if (sortImagesOnLoad) {
  data.sort(() => Math.random() - 0.5);
}

export default data;
