import React, { useState, useRef, useEffect, useContext, Fragment } from 'react';
import P from 'prop-types';
import data from '../../data';
import { GalleryConfig } from '../../config';
import { ProcessBarContext } from '../../contexts/ProcessBarContext';
import ProcessBar from '../../components/ProcessBar';
import ArrowButtom from '../ArrowButtom';
import GalleryCard from '../GalleryCard';
import FullScreenModal from '../FullScreenModal';
import { ModalContext } from '../../contexts/ModalContext';

import './style.scss';

function GalleryContainer() {
  // config
  const { masterIntervalDelay, numberOfImages, changingGap, transitionDuration, delayInc } = GalleryConfig;
  // contexts
  const { resetProgressBar, stopProgressBar } = useContext(ProcessBarContext);
  const { isModalVisible } = useContext(ModalContext);
  // states
  const [allImages, setAllImages] = useState([...data]);
  const [visibleImages, setVisibleImages] = useState(data.slice(0, numberOfImages));
  const galleryCard = useRef([]);
  const MASTERINTERVAL = useRef(null);

  // methods
  function imagesFadeOut() {
    var duration = transitionDuration;
    var delay = 0;
    galleryCard.current.map((module, n) => {
      galleryCard.current[n].style.transitionDuration = `${duration}ms`;
      galleryCard.current[n].style.transitionTimingFunction = `ease-in`;
      galleryCard.current[n].style.transitionDelay = `${delay}ms`;
      galleryCard.current[n].style.opacity = '0';
      setTimeout(() => {
        galleryCard.current[n].style.backgroundImage = 'none';
      }, duration + delay);
      delay = delay + delayInc;
    });
  }

  function imagesFadeIn() {
    var duration = transitionDuration;
    var delay = 0;
    setTimeout(() => {
      galleryCard.current.map((module, n) => {
        galleryCard.current[n].style.transitionDuration = `${duration * 5}ms`;
        galleryCard.current[n].style.transitionTimingFunction = `ease-out`;
        galleryCard.current[n].style.transitionDelay = `${delay}ms`;
        galleryCard.current[n].style.opacity = '1';
        delay = delay + delayInc;
      });
    }, duration + delay + duration);
  }

  // RollGalerry
  const directions = {
    FORWARD: 'FORWARD',
    BACKWARD: 'BACKWARD',
  };
  const { FORWARD, BACKWARD } = directions;

  function rollGallery(direction) {
    switch (direction) {
      case FORWARD:
        {
          setAllImages((images) => {
            const newImg = [...images];
            const firsts = newImg.splice(0, changingGap);
            newImg.push(...firsts);
            return newImg;
          });
        }
        break;

      case BACKWARD:
        {
          setAllImages((images) => {
            const newImg = [...images];
            const lasts = newImg.splice(-changingGap);
            newImg.unshift(...lasts);
            return newImg;
          });
        }
        break;
    }
  }
  function updateVisibleImages() {
    const newImages = allImages.slice(0, numberOfImages);
    let duration = transitionDuration;
    let delay = 0;
    setTimeout(() => {
      setVisibleImages(newImages);
    }, duration + delay + duration);
  }
  useEffect(() => {
    if (JSON.stringify(allImages.slice(0, numberOfImages)) != JSON.stringify(visibleImages)) {
      imagesFadeOut();
      updateVisibleImages();
      imagesFadeIn();
    }
  }, [allImages]);
  // finish RollGalerry

  // intervals
  const stopInterval = () => {
    if (MASTERINTERVAL.current) {
      clearInterval(MASTERINTERVAL.current);
      stopProgressBar();
    }
  };

  const startInterval = () => {
    resetProgressBar();
    MASTERINTERVAL.current = setInterval(() => {
      rollGallery(FORWARD);
      resetProgressBar();
    }, masterIntervalDelay);
  };

  const resetInterval = () => {
    stopInterval();
    startInterval();
  };

  // this useEffect will init the interval for the first time , and toggle stop/restart interval by opening/closing the modal to see the photo larger
  useEffect(() => {
    isModalVisible ? stopInterval() : resetInterval();
  }, [isModalVisible]);

  //
  return (
    <figure
      role="group"
      className="gallery-mosaic-wrapper"
    >
      <ArrowButtom
        position="left"
        onClick={() => {
          rollGallery(BACKWARD);
          resetInterval();
        }}
      />
      <ArrowButtom
        position="right"
        onClick={() => {
          rollGallery(FORWARD);
          resetInterval();
        }}
      />

      <ProcessBar position="top" />
      <FullScreenModal />
      <div className="gallery-mosaic">
        {visibleImages.map((module, n) => {
          return (
            <Fragment key={n}>
              <GalleryCard
                allImages={[...allImages]}
                imageId={visibleImages[n].id}
                imageIndex={allImages.findIndex((item) => item.id === visibleImages[n].id)}
                imageSrc={visibleImages[n].backgroundImage}
                imageAlt={visibleImages[n].alt}
                refProp={(e) => {
                  galleryCard.current[n] = e;
                }}
                figcaption={`${visibleImages[n].alt}`}
              />
            </Fragment>
          );
        })}
      </div>
      <ProcessBar position="bottom" />
    </figure>
  );
}
GalleryContainer.propTypes = {
  id: P.string,
};
export default GalleryContainer;
