import React, { useContext } from 'react';
import P from 'prop-types';
import { ProcessBarContext } from '../../contexts/ProcessBarContext';

import './style.scss';

function ProcessBar({ position }) {
  const { progressBarWidth, progressBarTransition } = useContext(ProcessBarContext);
  const styleForPosition = position === 'top' ? { top: 0 } : { bottom: 0 };

  return (
    <div
      className="progress_bar-wrapper"
      style={styleForPosition}
    >
      <div
        className="progress_bar"
        style={{ width: progressBarWidth, transition: progressBarTransition }}
      ></div>
    </div>
  );
}
ProcessBar.propTypes = {
  position: P.string,
};

export default ProcessBar;
